import Vue from 'vue'
import { initialize } from '@shein-aidc/bs-sdk-libs-manager'
import schttp from '@/public/src/services/schttp'
import UserInfoManager from '@/public/src/services/UserInfoManager/UserInfoManager.js'

export const businessLibInitializeForCSR = ({ router }) => {
  initialize({
    schttp,
    envs: {
      project: 'pwa',
      bffEnv: gbCommonInfo.bffEnv,
      webVersion: gbCommonInfo.WEB_VERSION,
      host: gbCommonInfo.host,
      lang: gbCommonInfo.lang,
      langPath: gbCommonInfo.langPath,
      siteUID: gbCommonInfo.SiteUID,
      appLanguage: gbCommonInfo.appLanguage,
      currency: gbCommonInfo.currency,
      csrf_token: gbCommonInfo.csrf_token,
      cssRight: gbCommonInfo.GB_cssRight,
      mir: gbCommonInfo.GB_cssRight ? 'rtl' : 'ltr',
      RESOURCE_SDK: gbCommonInfo.RESOURCE_SDK
    },
    getters: {
      isLogin() {
        return UserInfoManager.isLogin()
      },
      memberId() {
        return UserInfoManager.get({ key: 'memberId', actionType: 'bsLibs' })
      },
      SaPageInfo() {
        return window.SaPageInfo || {}
      }
    },
    langs: gbCommonInfo.language ? {
      Save: gbCommonInfo.language.SHEIN_KEY_PWA_15497,
      Search: gbCommonInfo.language.SHEIN_KEY_PWA_30497,
      Category: gbCommonInfo.language.SHEIN_KEY_PWA_14980,
      Message: gbCommonInfo.language.SHEIN_KEY_PWA_15201,
      Back: gbCommonInfo.language.SHEIN_KEY_PWA_15234,
      Cart: gbCommonInfo.language.SHEIN_KEY_PWA_15708,
      Me: gbCommonInfo.language.SHEIN_KEY_PWA_16036,
    } : null,
    router,
  }, Vue)
}


