import { union } from 'lodash'

// SiteUID type sDateMap
export const MESSAGE_DATE = {
  'A-1': ['pwar', 'pwaren', 'mtw', 'mjp', 'rwmar'],
  'A-2': ['m', 'pwus', 'mil', 'masia', 'rwm', 'rwmus'],
  'A-3': ['pwde', 'pwfr', 'pwes', 'pwit', 'mca', 'mse', 'pwmx', 'mth', 'meur', 'pwnl', 'pwau', 'pwma', 'mbr', 'msg', 'mnz', 'mpl', 'mvn', 'rwmde', 'rwmfr', 'rwmes', 'rwmit', 'rwmca', 'rwmmx', 'rwmau'],
  'A-4': ['pwuk', 'mru', 'pwin', 'mhk', 'mza', 'mcl', 'rwmuk', 'rwmin'],
}

const siteMap = {
  www: ['m', 'rwm'],
  us:	['pwus', 'rwmus'],
  ar:	['pwar', 'rwmar', 'pwaren'],
  de:	['pwde', 'rwmde'],
  fr:	['pwfr', 'rwmfr'],
  es:	['pwes', 'rwmes'],
  it:	['pwit', 'rwmit'],
  ru:	['mru'],
  uk:	['pwuk', 'rwmuk'],
  au:	['pwau', 'rwmau'],
  tw:	['mtw'],
  in:	['pwin', 'rwmin'],
  mx:	['pwmx', 'rwmmx'],
  il:	['mil'],
  th:	['mth'],
  nl:	['pwnl'],
  hk:	['mhk'],
  vn:	['mvn'],
  se:	['mse'],
  ca:	['mca', 'rwmca'],
  cl:	['mcl'],
  eur: ['meur'],
  ma:	['pwma'],
  br:	['mbr'],
  sg:	['msg'],
  za:	['mza'],
  nz:	['mnz'],
  pl:	['mpl'],
  asia:	['masia'],
  id:	['mid'],
  ph:	['mph'],
  pt:	['mpt'],
  jp:	['mjp'],
  my:	['mmy'],
  ch:	['mch'],
  roe:	['mroe'],
  euqs:	['meuqs']
}

const timeFormatMap = {
  'F-2': ['uk', 'ru', 'in', 'hk', 'za', 'cl', 'nl'],    // 'DD-MM-YYYY HH:mm'
  'F-3': ['se'],                                  // 'YYYY-MM-DD HH:mm'
  'F-4': ['de', 'fr', 'es', 'it', 'ca', 'mx', 'th', 'eur', 'au', 'ma', 'br', 'sg', 'nz', 'pl', 'vn', 'il'], // 'DD/MM/YYYY HH:mm'
  'F-5': ['www', 'us', 'asia'],                   // 'MM/DD/YYYY HH:mm' 默认站点
  'F-7': ['jp', 'ar', 'tw'],                // 'YYYY/MM/DD HH:mm' 
}
const timeFormatMapNoHours = {
  'A-4': ['uk', 'ru', 'in', 'hk', 'za', 'cl', 'nl'],    // DD-MM-YYYY
  'A-5': ['se'],                                        // YYYY-MM-DD
  'A-3': ['de', 'fr', 'es', 'it', 'ca', 'mx', 'th', 'eur', 'au', 'ma', 'br', 'sg', 'nz', 'pl', 'vn', 'il'], // DD/MM/YYYY
  'A-2': ['www', 'us', 'asia'],                         // MM/DD/YYYY' 默认站点
  'A-6': ['jp', 'ar', 'tw'],                            // YYYY/MM/DD 
}

let _COUPON_TIME_FORMAT_BY_ABT = {}
let _COUPON_TIME_FORMAT_NO_HOURS_BY_ABT = {}

for (const key in timeFormatMap) {
  if (Object.hasOwnProperty.call(timeFormatMap, key)) {
    const siteKeys = timeFormatMap[key]
    for (let index = 0; index < siteKeys.length; index++) {
      _COUPON_TIME_FORMAT_BY_ABT[key] = union((_COUPON_TIME_FORMAT_BY_ABT[key] || []).concat(siteMap[siteKeys[index]]))
    }
  }
}

for (const key in timeFormatMapNoHours) {
  if (Object.hasOwnProperty.call(timeFormatMapNoHours, key)) {
    const siteKeys = timeFormatMapNoHours[key]
    for (let index = 0; index < siteKeys.length; index++) {
      _COUPON_TIME_FORMAT_NO_HOURS_BY_ABT[key] = union((_COUPON_TIME_FORMAT_NO_HOURS_BY_ABT[key] || []).concat(siteMap[siteKeys[index]]))
    }
  }
}

export const COUPON_TIME_FORMAT_BY_ABT = Object.freeze(_COUPON_TIME_FORMAT_BY_ABT) // 携带时分
export const COUPON_TIME_FORMAT_NO_HOURS_BY_ABT = Object.freeze(_COUPON_TIME_FORMAT_NO_HOURS_BY_ABT) // 不携带时分

export const ORDER_DATE = {
  'A-1': ['pwar', 'pwaren', 'mtw', 'mjp', 'rwmar'],
  'B-2': ['m', 'pwus', 'mil', 'masia', 'rwm', 'rwmus'],
  'B-3': ['pwde', 'pwfr', 'pwes', 'pwit', 'mca', 'mse', 'pwmx', 'mth', 'meur', 'pwnl', 'pwau', 'pwma', 'mbr', 'msg', 'mnz', 'mpl', 'mvn', 'pwuk', 'mru', 'pwin', 'mhk', 'mza', 'mcl', 'rwmde', 'rwmfr', 'rwmes', 'rwmit', 'rwmca', 'rwmmx', 'rwmau', 'rwmuk', 'rwmin']
}

export const FULL_ORDER_DATE = {
  'E-7': ['pwar', 'pwaren', 'mtw', 'mjp', 'rwmar'],
  'E-1': ['m', 'pwus', 'mil', 'masia', 'rwm', 'rwmus'],
  'E-6': ['pwde', 'pwfr', 'pwes', 'pwit', 'mca', 'mse', 'pwmx', 'mth', 'meur', 'pwnl', 'pwau', 'pwma', 'mbr', 'msg', 'mnz', 'mpl', 'mvn', 'pwuk', 'mru', 'pwin', 'mhk', 'mza', 'mcl', 'rwmde', 'rwmfr', 'rwmes', 'rwmit', 'rwmca', 'rwmmx', 'rwmau', 'rwmuk', 'rwmin']
}

// 月日系列
export const SIMPLE_ORDER_DATE = {
  'D-1': ['pwar', 'pwaren', 'mtw', 'mjp', 'rwmar'],
  'D-5': ['m', 'pwus', 'mil', 'masia', 'rwm', 'rwmus'],
  'D-6': ['pwde', 'pwfr', 'pwes', 'pwit', 'mca', 'mse', 'pwmx', 'mth', 'meur', 'pwnl', 'pwau', 'pwma', 'mbr', 'msg', 'mnz', 'mpl', 'mvn', 'pwuk', 'mru', 'pwin', 'mhk', 'mza', 'mcl', 'rwmde', 'rwmfr', 'rwmes', 'rwmit', 'rwmca', 'rwmmx', 'rwmau', 'rwmuk', 'rwmin']
}

// lang type sDateMap for timeFormater
export const T_DATE = {
  'A-3': ['hk'],
  'A-5': ['tw', 'ar'],
  'B-2': ['en', 'us', 'il', 'asia']
}
export const T_SHORT_DATE = {
  'D-2': ['hk'],
  'D-4': ['tw', 'ar'],
  'D-5': ['en', 'us', 'il']
}
export const T_DATE_TIME = {
  'E-4': ['hk'],
  'E-3': ['tw', 'ar'],
  'E-1': ['en', 'us', 'il']
}
export const T_SHORT_DATE_TIME = {
  'F-4': ['hk'],
  'F-3': ['tw', 'ar'],
  'F-1': ['en', 'us', 'il']
}

export const C_YEAR_FORMAT_MAP = {
  'A-1': ['pwar', 'pwaren',],
  'A-2': ['m', 'iosshus', 'mil', 'masia'],
  'A-3': ['pwde', 'pwfr', 'pwes', 'pwit', 'mca', 'mse', 'pwmx', 'mth', 'meur', 'pwnl', 'pwau', 'pwma', 'mbr', 'msg', 'mnz', 'mpl', 'mvn'],
  'A-4': ['pwuk', 'mru', 'pwin', 'mhk', 'mza', 'mcl'],
}
export const C_MONTH_FORMAT_MAP  = {
  'D-1': ['pwar', 'pwaren',],
  'D-2': ['m', 'iosshus', 'mil', 'masia'],
  'D-3': ['pwde', 'pwfr', 'pwes', 'pwit', 'mca', 'mse', 'pwmx', 'mth', 'meur', 'pwnl', 'pwau', 'pwma', 'mbr', 'msg', 'mnz', 'mpl', 'mvn'],
  'D-4': ['pwuk', 'mru', 'pwin', 'mhk', 'mza', 'mcl'],
}
