import Vue from 'vue'
import container from './container.vue'
import createStore from './store'

export function createApp () {
  let store = createStore()
  const app = new Vue({
    store,
    render: h => h(container)
  })
  return { app, store }
}
